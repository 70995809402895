import React, {useState} from 'react';
import './App.css';
import jason from './language.json';

const App = () => {
    React.useEffect(() => {
        const menuBtn = document.querySelector('.menu-btn');
        const square2 = document.querySelector('.nav-close');
        const headerText = document.querySelector('.headerText');
        const navText = document.querySelector('.navText');
        const navLinks = document.querySelectorAll('.navLinks');
        const gradient = document.querySelector('.gradient');
        let menuOpen = false;
        const openNav = () => {
          if(!menuOpen) {
            menuBtn.classList.add('open');
            square2.classList.add('nav-open');
            headerText.style.opacity = '0';
            document.body.style.overflow = 'hidden';
            navText.style.opacity = '1';
            navText.style.width = '100vw';
            setTimeout( ()=>{gradient.style.display = 'none';},600)
            menuOpen = true;
          } else {
            menuBtn.classList.remove('open');
            square2.classList.remove('nav-open');
            headerText.style.opacity = '1';
            document.body.style.overflowY = 'visible';
            navText.style.opacity = '0';
            navText.style.width = '0';
            gradient.style.display = 'block';
            menuOpen = false;
          }
        }
    
        menuBtn.addEventListener('click',openNav);
    
        navLinks.forEach(navLink => {
          navLink.addEventListener('click', () => {
            openNav()
          })
        })
    
    
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if(entry.isIntersecting){
              entry.target.classList.add('show');
            }
          });
        });
        const hiddenElemenst = document.querySelectorAll('.hidden');
        hiddenElemenst.forEach((el) => observer.observe(el));
      }, []);

    const [lang,setlang] = useState("en");
  return (
    <>
      <header>
        <div className="gradient"></div>
        <img src='http://www.kat-pol-transport.pl/img/logo_katpol(2).png' alt="" className="logo hidden"/>
        <div className="language">
            <button onClick={() => setlang("en")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/1920px-Flag_of_the_United_Kingdom_%281-2%29.svg.png" alt="EN"/></button>
            <button onClick={() => setlang("pl")}><img src="https://upload.wikimedia.org/wikipedia/en/thumb/1/12/Flag_of_Poland.svg/1920px-Flag_of_Poland.svg.png" alt="PL"/></button>
            <button onClick={() => setlang("de")}><img src="https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/1920px-Flag_of_Germany.svg.png" alt="DE"/></button>
        </div>
        <div className="nav-close">
            <div className="menu-btn">
                <div className="menu-btn__burger"></div>
            </div>
            <div className="navText">
                <nav>
                    <ul>
                        <li><a className="navLinks hidden" href="#oNas">{jason[lang].navLinks[0]}</a></li>
                        <li><a className="navLinks hidden" href="#oferta">{jason[lang].navLinks[1]}</a></li>
                        <li><a className="navLinks hidden" href="#uslugi">{jason[lang].navLinks[2]}</a></li>
                        <li><a className="navLinks hidden" href="#kontakt">{jason[lang].navLinks[3]}</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="headerText hidden">
            <h1>{jason[lang].header}</h1>
            <h3>{jason[lang].subheader}</h3>
            <a href="mailto:biuro@kat-pol.pl,biuro@kat-pol-transport.pl,katpolexpress1@gmail.com,katpolexpress@gmail.com" className="mailTo"><span className="underEffectHover">{jason[lang].contactLink}</span></a>
        </div>
        
        <a className="headerFacebook" href="https://www.facebook.com/people/Kat-Pol-Transport-Mi%C4%99dzynarodowy/100057242930954">
            <i className="fa-brands fa-square-facebook"></i>
        </a>
        <a  className="lisc" href="#eco">
            <i className="fa-solid fa-leaf"></i>
            ECO
        </a>
    </header>

    <section className="oNas" id="oNas">
        <div className="oNasImg hidden"></div>
        <div className="oNasText hidden">
            <span className="red">KAT-POL Express</span>{jason[lang].aboutUs}
        </div>
    </section>

    <section className="oferta" id="oferta">
        <div className="content">
            <div className="ofertaText hidden">
                <div>{jason[lang].offer.title}</div>
                <ul>
                    <li>{jason[lang].offer.items[0]}</li>
                    <li>{jason[lang].offer.items[1]}</li>
                    <li>{jason[lang].offer.items[2]}</li>
                    <li>{jason[lang].offer.items[3]}</li>
                    <li>{jason[lang].offer.items[4]}</li>
                    <li>{jason[lang].offer.items[5]}</li>
                    <li>{jason[lang].offer.items[6]}</li>
                    <li>{jason[lang].offer.items[7]}</li>
                </ul>
            </div>
            <div className="ofertaImg hidden"></div>
        </div>
    </section>
    <section className="eco " id="eco">
        <h1 className="hidden"><i className="fa-solid fa-leaf"></i>{jason[lang].eco.title}</h1>
        <div className="ecoText hidden">{jason[lang].eco.text}</div>
    </section>
    <section className="uslugi" id="uslugi">
        <h1>{jason[lang].services}</h1><br />
        <div className="ulsugiFlex">
            <div className="uslugiPoj hidden"><i className="fa-solid fa-truck-fast"></i><div className="uslugiText">{jason[lang].servicesItems.land}</div></div>
            <div className="uslugiPoj hidden"><i className="fa-solid fa-ship"></i><div className="uslugiText">{jason[lang].servicesItems.sea}</div></div>
            <div className="uslugiPoj hidden"><i className="fa-solid fa-plane-departure"></i><div className="uslugiText">{jason[lang].servicesItems.air}</div></div>
        </div>
    </section>
    <section className="kontakt " id="kontakt">
        <h1>{jason[lang].contact.title}</h1>
        <div className="sales">
            <h1>{jason[lang].contact.titles[0]}</h1>
            <div className="prac">
                <div className="pracPoj">
                    Paweł Maćkowiak<br />
                    <a href="mailto:biuro@kat-pol.pl">biuro@kat-pol.pl</a><br />
                    <a href="tel:+48 508 147 565">+48 508 147 565</a>
                </div>
                <div className="pracPoj">
                    Artur Gumowski<br />
                    <a href="mailto:biuro@kat-pol-transport.pl">biuro@kat-pol-transport.pl</a><br />
                    <a href="tel:+48 503 099 077">+48 503 099 077</a>
                </div>
            </div>
        </div>
        <div className="logi">
            <h1>{jason[lang].contact.titles[1]}</h1>
            <div className="prac">
                <div className="pracPoj">
                    Daniel Maćkowiak<br />
                    <a href="mailto:katpolexpress1@gmail.com">katpolexpress1@gmail.com</a><br />
                    <a href="tel:+48 507 951 816">+48 507 951 816</a>
                </div>
                <div className="pracPoj">
                    Kamil Rokitnicki<br />
                    <a href="mailto:katpolexpress@gmail.com">katpolexpress@gmail.com</a><br />
                    <a href="tel:+48 698 189 162">+48 698 189 162</a>
                </div>
            </div>
       </div>
        <div className="acco">
            <h1>{jason[lang].contact.titles[2]}</h1>
            <div className="prac">
                <div className="pracPoj">
                    Katarzyna Maćkowiak<br />
                    <a href="mailto:ksiegowosc@kat-pol.pl">ksiegowosc@kat-pol.pl</a><br />
                    <a href="tel:+48 508 147 555">+48 508 147 555</a>
                </div>
            </div>
        </div>
        <a className="facebook" href="https://www.facebook.com/people/Kat-Pol-Transport-Mi%C4%99dzynarodowy/100057242930954">
            <i className="fa-brands fa-square-facebook"></i>
        </a>
    </section>
    <footer>
        ul. Słoneczna 7D, Stanisławów Pierwszy, 05-126 Nieporęt tel.kom. 0048 508 147 565 tel./fax: 0048 22 772 42 23 e-mali: biuro@kat-pol-transport.pl
    </footer>
    </>
  );
}

export default App;